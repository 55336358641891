import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'

import vuetify from './plugins/vuetify';

import App from './App.vue'
import './main.scss'
import '@mdi/font/css/materialdesignicons.min.css'

import moment from "moment";
moment.locale('ru');

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueMarkdown from 'vue-markdown'
Vue.component('vue-markdown', VueMarkdown);

import VuexORM from '@vuex-orm/core'
Vue.use(Vuex)

import VuexORMAxios from '@vuex-orm/plugin-axios'
import axios from "axios";
VuexORM.use(VuexORMAxios, { axios });

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import User from "@/model/User";
import UserProfile from "@/model/UserProfile";
import UserSpec from "@/model/UserSpec";
import ComRoom from "@/model/ComRoom";
import ComBranch from "@/model/ComBranch";
import ComPlace from "@/model/ComPlace";
import Visit from "@/model/Visit";
import Spec from "@/model/Spec";
import VisitStatus from "@/model/VisitStatus";
import PromoSource from "@/model/PromoSource";
import UserSpecPlan from "@/model/UserSpecPlan";
import ComBuilding from "@/model/ComBuilding";
import ComFloor from "@/model/ComFloor";
import ComDiv from "@/model/ComDiv";
import Com from "@/model/Com";
import ServiceCat from "@/model/ServiceCat";
import Service from "@/model/Service";
import Log from "@/model/Log";


const database = new VuexORM.Database()
database.register(User)
database.register(UserProfile)
database.register(UserSpec)
database.register(UserSpecPlan)
database.register(Spec)
database.register(Com)
database.register(ComBranch)
database.register(ComDiv)
database.register(ComBuilding)
database.register(ComFloor)
database.register(ComRoom)
database.register(ComPlace)
database.register(Visit)
database.register(VisitStatus)
database.register(PromoSource)
database.register(ServiceCat)
database.register(Service)
database.register(Log)

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

Vue.filter('dateYears', function (v){
  console.warn('filter dateYears deprecated');
  if (v==null) return '';
  if (v.length!==10) return '';
  return '('+moment(v, 'YYYY-MM-DD HH:mm:ss').fromNow(true)+')';
});
Vue.filter('date', function (v){
  console.warn('filter date deprecated');
  if(Vue.prototype.$tools.isEmpty(v)) return '';
  return moment(v, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')
});

Vue.prototype.$apiCache = [];
Vue.prototype.$ws = null;

Vue.prototype.$inactiveStatuses = ['notReady','cancel','rejected'];
Vue.prototype.$activeStatuses = ['pre','confirmed','ready','active','complete','move','self'];

Vue.prototype.$tools = {
  throttle(callback, time=1000, name=null){
    if(name===null)
      name = 'main';
    name = 'throttleTimer_'+name;
    if(typeof window[name]!=='undefined')
      clearTimeout(window[name]);
    window[name] = setTimeout(()=>{
      callback();
    },time);
  },
  findById(arr, v){
    let s = '';
    arr.forEach((v1)=>{
      if (v1.id===v)
        s = v1;
    });
    return s;
  },
  isEmpty(v) {
    if (typeof v == 'undefined') return true;
    if (v == null) return true;
    return v.length === 0;
  },
  parseNumber(v, decimal=2) {
    if (typeof v === 'undefined') return 0;
    if (v==='') return 0;
    if (v===null) return 0;
    if (isNaN(v)) return 0;
    v = v+'';
    v = parseFloat(v)
    v = parseFloat(v.toFixed(decimal))
    return v===0 ? 0 : v;
  },
  notNull(obj, path) {
    let a = path.split('.').reduce((obj, prop) => {
      return obj && obj[prop] ? obj[prop] : null;
    }, obj)
    return a!==null
  },
  mergeUniqById(arr1, arr2) {
    let arr10 = [];
    arr1.filter(v=>v!==null).forEach(v=>{arr10[v.id] = v;});
    arr2.filter(v=>v!==null).forEach(v=>{arr10[v.id] = v;});
    let arr11 = [];
    arr10.forEach(v=>{arr11.push(v)});
    return arr11;
  },
  nl2br(v) {
    return String(v).replace(/([^>])\n/g, '$1<br/>')
  },
  dump(v) {
    console.log(v)
    return v;
  },
  date(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    return moment(v, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')
  },
  years(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    return moment(v, 'YYYY-MM-DD HH:mm:ss').fromNow(true);
  },
  dateText(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    if (typeof v == 'object') return moment(v).format('dddd, DD MMMM');
    return moment(v, 'YYYY-MM-DD').format('dddd, DD MMMM');
  },
  dateTime(v, addText=true){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    return moment(v, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'+(addText ? ' в':'')+' HH:mm')
  },
  dateFull(v){
    return moment(v, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD MMMM YYYY')
  },
  dateTimeFormat(v, toFormat, fromFormat='YYYY-MM-DD HH:mm:ss', addMinutes=0){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    let r = typeof fromFormat==='undefined' || fromFormat===null ?  moment(v) : moment(v, fromFormat);
    if(v==='now')
      r = moment();
    if(addMinutes!==0)
      r = r.add(addMinutes, 'minutes');
    return r.format(toFormat);
  },
  secondsToTime(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    return moment().startOf('day').seconds(v).format('mm:ss');
  },
  dateTimeFull(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';
    return moment(v, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD.MM.YYYY в HH:mm')
  },
  isDateUnder(date, v, format=''){
    if(Vue.prototype.$tools.isEmpty(date)) return false;
    return moment().diff(moment(date, format), 'years')<v;
  },
  time(v){
    return moment(v, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
  },
  price(v, emptyStr=null){
    let v1 = parseFloat(v);
    if(isNaN(v1)) return emptyStr===null ? '' : emptyStr;
    let v2 = v1.toFixed(2);
    if(emptyStr!==null && v2==='0.00')
      return emptyStr;
    return v2.replace(/\d(?=(\d{3})+\.)/g, '$&,')+' Р';
  },
  percent(v1, v2, emptyStr=0, onlyValue=false){
    if(Vue.prototype.$tools.isEmpty(v1) || Vue.prototype.$tools.isEmpty(v2))
      return emptyStr+(onlyValue ? '' : ' %');

    let v10 = parseFloat(v1).toFixed(2);
    let v20 = parseFloat(v2).toFixed(2);

    if(v10==='0.00' || v20==='0.00')
      return emptyStr+(onlyValue ? '' : ' %');

    let v30 = ((v10/v20)*100).toFixed(0);
    return v30+(onlyValue ? '' : ' %');
  },
  arrayGen(arr, addFields){
    arr.forEach((v, k)=>{
      arr[k] = Object.assign(v, addFields(v));
    });
    return arr;
  },
  openUrl(url, newWindow=false){
    if(newWindow===true)
      window.open(url, "_blank")
    else
      window.location = url
  },
  copy(v){
    return JSON.parse(JSON.stringify(v))
  },
  equals(a,b){
    return JSON.stringify(a)===JSON.stringify(b)
  },
  filterByGlobalState(arr, name){
    if(typeof localStorage['globalState_'+name]!=='undefined') {
      let arr1 = JSON.parse(localStorage['globalState_'+name]);
      if(arr1.length>0){
        arr1.forEach((v)=>{
          v.id = parseInt(v.id);
        });
        return arr.filter(v=>v.comBranch===null || arr1.some(v1 =>v.comBranch.id===v1.id))
      }
    }
    return arr;
  },

  pageVisibleInit(){
    if(typeof window.pvcTimer!=='undefined')
      clearTimeout(window.pvcTimer);
    window.pvcTimer = setInterval(()=>{
      if(document.visibilityState==='visible')
        window.pvcLastTime = moment().unix();
      window.pvcAllow = moment().unix()-window.pvcLastTime<60
    },1000);
  },
  pageVisibleCallback(acceptCallback=null, rejectCallback=null){
    let pvcAllow = typeof window.pvcLastTime=='undefined' ? true : window.pvcAllow

    if(document.visibilityState==='visible' || pvcAllow){
      if(acceptCallback!==null){
        Vue.prototype.$tools.throttle(()=>{
          acceptCallback();
        }, 500, 'pageVisible');
      }
    } else {
      if(rejectCallback!==null)
        rejectCallback();
    }
  },

  arrSum(arr, field){
    let r = 0;
    arr.forEach(v=>{
      r+=parseFloat(v[field]);
    });
    return r;
  },
  timeFormatter($event) {
    let t = $event.target.value
    let exclude = ['3','4','5','6','7','8','9']
    if (t.length==1&&exclude.includes(t)) {
      return $event.target.value = ''
    }
    else {
      let str = t.substr(0,5).replace(':','').split('')
      if (t.length >= 3) {
        str.splice(2, 0, ':')
        let t = str.join('')

        return $event.target.value = t
        
      }
    }  
  },
  firstLatterUppercase($event) {
    let str = $event.target.value;
    if (str.length>0) {
      let newStr = str[0].toUpperCase() + str.slice(1);
      return $event.target.value = newStr;
    }
    else {
      return ''
    }
  },
  nameFormatter(str){
    if (str&&str.length>0) {
      let newStr = str[0].toUpperCase() + str.slice(1);
      newStr = newStr.trim();

      return newStr;
    }
    else {
      return '';
    }
  },
  priceFormatter(str){
    if (typeof str === 'string') {
      let replacedStr = str.replace(',', '.');
      return replacedStr;
    } else {
      return str;
    }
  },
  copyToClipboard(text) {
    navigator.clipboard.writeText(text);
  },
  dateYears(date, format = 'DD.MM.YYYY') {
    const currentDate = moment(new Date());
    const birthDate = moment(date, format);
  
    if (!birthDate.isValid()) return '';
  
    const years = currentDate.diff(birthDate, 'years');
    const months = currentDate.diff(birthDate.clone().add(years, 'years'), 'months');
  
    function getAgeText(age) {
      if (age % 10 === 1 && age % 100 !== 11) {
        return "год";
      } else if (age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)) {
        return "года";
      } else {
        return "лет";
      }
    }
  
    function getMonthText(months) {
      if (months === 1) {
        return "месяц";
      } else if (months >= 2 && months <= 4) {
        return "месяца";
      } else {
        return "месяцев";
      }
    }
  
    return `${years} ${getAgeText(years)} ${months} ${getMonthText(months)}`;
  },  
  dateYearsSimple(date,format='DD.MM.YYYY'){
    const currentDate = moment(new Date());
    const birthDate = moment(date, format);

    const duration = moment.duration(currentDate.diff(birthDate));
    const years = duration.years();

    if (birthDate==null) return '';
    if (!birthDate.isValid()) return '';

    let text = '';
    if (years % 10 === 1 && years % 100 !== 11)
      text = 'год';
    else if (years % 10 >= 2 && years % 10 <= 4 && (years % 100 < 10 || years % 100 >= 20))
      text = 'года';
    else
      text = 'лет';

    return years+' '+text;
  },
  truncateText(text, maxLength = 200) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength - 3).trim() + '...';
    }
  },
  pageHeight(){
    return window.innerHeight-(18 * 2)-48;
  },

  misDesktopStatus(callback){
    axios.get('http://localhost:3035/api/status/')
      .then(() =>{
        callback(true);
      })
      .catch((e) =>{
        callback(false);
        console.log(e);
      });
  },
  misDesktopOpenFile(fileUrl, fileName, uploadUrl, uploadParams, success, error){
    axios.post('http://localhost:3035/api/file/', {
      fileUrl:fileUrl,
      fileName:fileName,
      uploadUrl:uploadUrl,
      uploadParams:uploadParams,
    })
      .then(() =>{
        if(success!==null) success();
      })
      .catch(() =>{
        if(error!==null) error();
      });
  },

  //selects
  isSelectAll(items, itemsSelects){
    return items.length>0 && items.length===itemsSelects.length;
  },
  isSelectIntermidiate(items, itemsSelects){
    return items.length>0 && itemsSelects.length>0 && items.length!==itemsSelects.length;
  },
  isSelectSome(items, itemsSelects){
    return items.length>0 && itemsSelects.length>0;
  },
  isSelect(itemsSelects, item){
    return itemsSelects.filter(v=>v.id===item.id).length>0;
  },
  selectToggle(itemsSelects, item){
    if(this.isSelect(itemsSelects, item)) {
      itemsSelects.forEach((v, k)=>{
        if(v.id===item.id)
          itemsSelects.splice(k, 1)
      });
    } else {
      itemsSelects.push(item);
    }
  },
  selectAllToggle(items, itemsSelects){
    if(this.isSelectAll(items, itemsSelects)) {
      itemsSelects.splice(0);
    } else {
      items.forEach((v)=>{
        itemsSelects.push(v);
      });
    }
  },

  phoneFormat(v){
    if(Vue.prototype.$tools.isEmpty(v)) return '';

    let p = '' + v;
    if(p.length===11){
      let m = p.match(/^(\d)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (m){
        let f = parseInt(p.substring(0, 1));
        if(f===7)
          f = 8;
        return [f, ' (', m[2], ') ', m[3], '-', m[4], '-', m[5]].join('');
      }
    }
    return p;
  },
  userFullName(user,short=false) {
    if (!short)
      return `${user.lastName} ${user.firstName} ${user.secondName}`;
    else 
    return `${user.lastName} ${user.firstName?.[0]}. ${user.secondName?.[0]}.`;
  },
  download($refs,name='список'){
    console.log($refs);
    let a = '\ufeff';
    let table = $refs.nTable.$el.querySelector('.v-data-table__wrapper table');
    console.log(table)
    table.querySelectorAll('thead th').forEach((th)=>{
      if(th.querySelector('span'))
      a +=th.querySelector('span').textContent+';'
    })
    a +=`
`;
    table.querySelectorAll('tbody tr').forEach((tr)=>{
      tr.querySelectorAll('td').forEach((td)=>{
        if (!td.querySelector('.v-input--checkbox')) {//exclude checkbox
          let v = td.textContent.replace('\n', '. ');

          //content for print
          if (td.hasAttribute('data-print')) {
            v  = td.getAttribute('data-print');
          }

          //v = v.replace('-', ' ');
          v = v.replace(/"/g, '');
          v = v.replace(/;/g, '');
          v = v.replace(/\n/g, ' ');

          if(v.endsWith(" Р")){
            v = v.substring(0, v.length-2)
            v = v.replace(',', '');
            v = v.replace(/\./g, ',');
          }

          v = v.trim();

          a +=v+';'
        }  
      })
      a +=`
`;
    })

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(a));
    element.setAttribute('download', name+'.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
};

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  vuetify,
  store: new Vuex.Store({
    state: {
      comBranchId: null,
      page:{
        snackbars: [],
      },
      callPhone:null,

      //old
      user:null,
      //new
      session:null,

      speechAssistantMainEnabled:true,
      tmp:{},
      usersSearchInput: ''//search filter field
    },
    mutations: {
      alertSuccess(state, mess){
        if(typeof mess === 'object'){
          state.page.snackbars.push({
            message:mess.message,
            timeout: mess.timeoutSec*1000,
            color:"green",
            right: true,
            bottom: true
          })
        } else {
          state.page.snackbars.push({
            message:mess,
            timeout: 15000,
            color:"green",
            right: true,
            bottom: true
          });
        }
      },
      alertWarning(state, mess){
        state.page.snackbars.push({
          message:mess,
          timeout: 15000,
          color:"orange darken-1",
          right: true,
          bottom: true
        })
      },
      alertError(state, mess){
        state.page.snackbars.push({
          message:mess,
          timeout: 15000,
          color:"red",
          right: true,
          bottom: true
        })
      },
      call(state, phone){
        state.callPhone = phone;
      },
      setUsersSearchInputValue (state, newValue) {//search filter field
        state.usersSearchInput = newValue
      },
      // setComBranchId (state, newValue) {//search filter field
      //   state.comBranchId = newValue
      // }
    },
    actions: {
      setUsersSearchInputValue ({ commit }, newValue) {//search filter field
        commit('setUsersSearchInputValue', newValue)
      },
      // setComBranchId ({ commit }, newValue) {//combranch filter field
      //   commit('setComBranchId', newValue)
      // }
    },
    plugins: [VuexORM.install(database)]
  })
}).$mount('#app')


