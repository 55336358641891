<template>
  <v-app>
    <v-app-bar
        v-if="$store.state.user!=null" app dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'"
        :extension-height="tabsShow ? 48 : 0"
    >
      <portal-target v-show="backShow" name="back" style="background-color:transparent" @change="backChange"/>
      <v-toolbar-title>
        <portal-target v-show="titleShow" name="title" style="background-color:transparent" @change="titleChange"/>
        <div v-if="!titleShow">{{$router.currentRoute.meta.title}}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <speech-assistant v-if="$store.state.speechAssistantMainEnabled"/>
      <notif/>
      <call-group-widget ref="callerGroupWidget"/>
      <user-bar :wsStatus="wsStatus"/>
      <template v-slot:extension>
        <portal-target name="tabs" class="full-width d-inline-block" style="background-color:transparent" @change="tabsChange"/>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-if="$store.state.user!=null" app :mini-variant="menuIsMini" permanent>
      <v-toolbar class="logo-toolbar" dark :color="!$vuetify.theme.dark ? 'rgb(2 146 77)' : 'rgb(40 40 40)'">
          <template>
            <v-sheet min-width="220" class="overflow-hidden" color="transparent">
              <v-row no-gutters>
                <v-col cols="auto" align-self="center">
                  <img :src="LogoIcon" width="38"/>
                </v-col>
                <v-col v-if="!menuIsMini" class="text--white ml-4">
                    <div class="body-1 font-weight-medium">Mediana</div>
                    <div class="caption mt-n1">UI: {{versionText}}</div>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
      </v-toolbar>
      <v-list class="pa-0" dense>
        <v-list-item v-for="item in menu.filter(v=>v.show)" :key="item.title" :to="item.to" link>
          <v-list-item-icon class="mr-4 py-4">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="pa-0">
        <v-list-item @click="menuIsMini=!menuIsMini">
          <v-list-item-icon>
            <v-icon v-if="menuIsMini">mdi-arrow-right-drop-circle-outline</v-icon>
            <v-icon v-else>mdi-arrow-left-drop-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium text--secondary">Свернуть меню</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main v-if="$store.state.user!=null">
      <router-view/>
    </v-main>

    <login v-if="$store.state.user==null"/>

    <v-snackbars
        :objects.sync="$store.state.page.snackbars"
        class="overflow-hidden"
    >
      <template v-slot:default="{ message }">
        <div v-html="$tools.nl2br(message)" class="d-inline-block"></div>
      </template>
      <template v-slot:action="{ close }">
        <btn-close-timed
            @click="close()"
            color="white"
            :time="14000"
            :size="34"
        >
          <v-icon size="20">mdi-close</v-icon>
        </btn-close-timed>
      </template>
    </v-snackbars>
  </v-app>
</template>


<script>
import VSnackbars from "v-snackbars";
import Login from "@/views/User/Login.vue";
import Notif from "@/views/Notif/Notif";
import axios from "axios";
import Promise from "q";
import SpeechAssistant from "@/components/SpeechAssistant.vue";
import Session from "@/plugins/session";
import UserBar from "@/views/User/components/UserBar.vue";
import CallGroupWidget from "@/views/Call/CallGroupWidget.vue";
import WS from "@/plugins/ws";
import BtnCloseTimed from "@/componentsV2/base/BtnCloseTimed.vue";

export default {
  components:{
    BtnCloseTimed,
    CallGroupWidget, UserBar, SpeechAssistant, Notif, "v-snackbars": VSnackbars, Login},
  data: () => ({
    menu: [
      { title: 'Рабочий стол', perm:'users', show:true, icon: 'mdi-monitor', to:'/desktop/' },
      { title: 'Пользователи', perm:'users', show:true, icon: 'mdi-account-multiple-outline', to:'/user/' },
      { title: 'Визиты', perm:'visits', show:true, icon: 'mdi-calendar-today', to:'/visit/' },
      { title: 'Планы работы спец.', perm:'userSpecPlans', show:true, icon: 'mdi-calendar-account', to:'/userSpecPlans/' },
      { title: 'Прайс лист', perm:'services', show:true, icon: 'mdi-currency-rub', to:'/price/' },
      { title: 'Оказанные услуги', perm:'servicesRendered', show:true, icon: 'mdi-clipboard-text-search-outline', to:'/visitService/' },
      { title: 'Планы лечения', perm:'servicesRendered', show:true, icon: 'mdi-clipboard-list-outline', to:'/medPlans/' },
      { title: 'Мед. записи', perm:'servicesRendered', show:true, icon: 'mdi-file-document-multiple-outline', to:'/medRecords/' },
      { title: 'Финансы', perm:'finances', show:true, icon: 'mdi-cash-multiple', to:'/financeOld/' },
      { title: 'Финансы (тестирование)', perm:'finances', show:true, icon: 'mdi-circle', to:'/finance/' },
      { title: 'Аналитика', perm:'analytics', show:true, icon: 'mdi-chart-timeline-variant', to:'/analytic/' },
      { title: 'Чат (тестирование)', perm:'users', show:true, icon: 'mdi-forum-outline', to:'/chat/' },
      { title: 'Телефония', perm:'calls', show:true, icon: 'mdi-phone', to:'/call/' },
      { title: 'Настройки', perm:'settings', show:true, icon: 'mdi-cog-outline', to:'/settings/' },
      //{ title: 'Организации', icon: 'mdi-office-building' },
      //{ title: 'Оплата', icon: 'mdi-currency-usd' },
      //{ title: 'Документы', icon: 'mdi-file-document' },
    ],
    menuIsMini: true,
    meta: null,
    title: null,
    LogoIcon: require('@/assets/mediana-logo-small-white.svg'),
    titleShow: false,
    backShow: false,
    tabsShow: false,
    eventSource: null,
    ws: new WS(),
    wsStatus: null,
    visible:true,
  }),
  mounted() {
    this.$tools.pageVisibleInit();
    document.onvisibilitychange = () => {
      this.visible = document.visibilityState==='visible';
    };

    axios.interceptors.response.use(null, (error)=> {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode===400) {
        this.$tools.throttle(()=>{
          this.$store.commit('alertWarning', error.response.data.message);
        });
      }

      if (statusCode===403) {
        this.$tools.throttle(()=>{
          this.$store.commit('alertError', 'Авторизация истекла. Необходима повторная авторизация');
          this.ws.disconnect();
          Session.update(null);
          this.$store.state.user = null;
          this.$store.state.session = null;
        });
      }
      if (statusCode>=500)
        this.$tools.throttle(()=>{
          this.$store.commit('alertError', 'Ошибка в работе МИС. Если ошибка повторяется свяжитесь с тех. подержкой');
        });
      return Promise.reject(error);
    })
    this.$store.state.session = Session.read();
    this.ws.addStateCallback((state)=>{
      this.wsStatus = state.status;
      if(state.data!==null){
        if(state.data.channel==='call.update')
          this.$refs.callerGroupWidget.event(state.data.data);
      }
      if(state.status===WS.STATE_CLOSED && this.$store.state.user!==null && this.visible){
        this.$tools.throttle(()=>{
          this.ws.connect();
        }, 5000, 'wsConnect')
      }
    })

    if(this.$store.state.user!==null && this.visible)
      this.ws.connect();

    if(typeof localStorage.versionUI==='undefined' || localStorage.versionUI!==process.env.VUE_APP_VERSION){
      let v = process.env.VUE_APP_VERSION.split('.')[2];
      this.$store.commit('alertSuccess', 'Интерфейс Mediana обновлен. Версия от '+v.substring(6,8)+'.'+v.substring(4,6)+'.'+v.substring(0,4));
      localStorage.versionUI = process.env.VUE_APP_VERSION;
    }
  },

  watch: {
    '$store.state.user'() {
      if(!this.$tools.isEmpty(this.$store.state.user?.perms)){
        this.menu.forEach((v)=>{
          v.show = false;
          this.$store.state.user.perms.split(',').forEach((v1)=>{
            if(v.perm===v1)
              v.show = true;
          });
        });
      } else {
        this.menu.forEach((v)=>{
          v.show = false;
        });
      }
      if (this.$store.state.user!==null){
        if(this.visible)
          this.ws.connect();
      } else {
        this.ws.disconnect();
      }
    },
    visible(){
      if(this.$store.state.user!==null && this.visible){
        this.ws.connect();
      } else if(!this.visible){
        this.ws.disconnect();
      }
    }
  },
  computed: {
    versionText() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods:{
    titleChange(v){ this.titleShow = v },
    backChange(v){ this.backShow = v },
    tabsChange(v){ this.tabsShow = v },
  }
};
</script>

<style lang="scss" >
  .logo-toolbar {
    .v-toolbar__content {
      padding: 4px 8px;
    }
  }
</style>